import { ReservationModel } from "../SelectTimeOption/SelectTimeOption";
import { confirmButtonProps, useAppointmentConfirmation } from "./AppointmentConfirmationStyle";
import { useEffect, useState } from "react";
import { Checkmark24Filled } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { useMutation } from "@apollo/client";
import { CREATE_APPOINTMENT } from "../../graphql/mutation/appointment";

function AppointmentConfirmation(props: { disabled: boolean, payload: string | undefined, reservationModel: ReservationModel | null, onReservationConfirmed(reservationModel: ReservationModel | null): void }) {
    const style = useAppointmentConfirmation();

    const { reservationModel, onReservationConfirmed, disabled, payload } = props;

    const [reservationConfirmed, setReservationConfirmed] = useState<boolean>(false);

    useEffect(() => {
        setReservationConfirmed(false);
    }, [reservationModel])

    const [createAppointment] = useMutation(CREATE_APPOINTMENT);

    useEffect(() => {

    }, [])

    return (
        <div className={style.container}>
            <div className={style.controlButtons}>
                <div className={style.item}>
                    <Button
                        disabled={disabled || reservationConfirmed || reservationModel === null || payload === undefined}
                        onClick={() => {
                            if (!reservationConfirmed && reservationModel !== null) {
                                console.log(reservationModel);
                                setReservationConfirmed(true);

                                createAppointment({ variables: { reservationCode: reservationModel.reservationCode, payload: payload ?? '' } })
                                    .then(value => {
                                        onReservationConfirmed(reservationModel);
                                        setReservationConfirmed(true);
                                        console.log(value);
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        onReservationConfirmed(null);
                                        setReservationConfirmed(false);
                                    });
                            }
                        }}
                        icon={<Checkmark24Filled />}
                        {...confirmButtonProps}>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AppointmentConfirmation;