import { Button, Input, Label, useId, Text } from "@fluentui/react-components";
import React from "react";
import { useInputSupportTicketComponentStyle } from "./InputSupportTicketComponentStyle";
import { KeyRegular, PlayRegular } from "@fluentui/react-icons";

export declare type InputSupportTicketComponentProps = {
    onChanged(value: string): void
    readonly: boolean
}

function InputSupportTicketComponent(props: InputSupportTicketComponentProps) {
    const { onChanged, readonly } = props;
    const [input, setInput] = React.useState<string>('');

    const style = useInputSupportTicketComponentStyle();
    const beforeId = useId("content-before");
    return (
        <div className={style.container}>
            <div className={style.container2}>

                <div className={style.input}>

                    <Label htmlFor={beforeId}>Enter support ticket</Label>
                    <Input
                        className={style.inputText}
                        disabled={readonly}
                        placeholder='1234-ABCD'
                        value={input}
                        contentBefore={<KeyRegular />}
                        id={beforeId} onChange={(ev, data) => {
                            const value = data.value.toUpperCase();
                            if (value.length >= 10) return;
                            if (value.length === 4 && !input.endsWith('-')) {
                                setInput(value + '-');
                            } else {
                                setInput(value);
                            }
                        }} />
                </div>
                <Button
                    shape='square'
                    disabled={readonly || input.length <= 8}
                    icon={<PlayRegular />}
                    onClick={() => {
                        onChanged(input);
                    }}
                    appearance='primary'>
                </Button>
            </div>
            <div>
                <Text>You will be given the support ticket over the phone, when your session starts.</Text>
            </div>
        </div >
    )
}

export default InputSupportTicketComponent;
