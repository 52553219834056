import { makeStyles, shorthands } from "@fluentui/react-components";

export const useCallNowOptionBannerStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    title: {
    },
    contactBanner: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    logo: {
    },
    phone: {
    }
});
