import { makeStyles, shorthands } from "@fluentui/react-components";

export const useSupportOptionHeaderStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "inline-grid",
        alignItems: 'end',
        gridTemplateColumns: "repeat(1, 1fr)",
        gridTemplateRows: "repeat(1, 1fr)",
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    above: {
        gridRowStart: '1',
        gridColumnStart: '1',
        textAlign: 'end',
    },
    under: {
        gridRowStart: '1',
        gridColumnStart: '2',
    }
});
