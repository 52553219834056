import { cancelButtonProps, dropdownStyle as dropDownStyle, useSelectSupportOptionButtonStyle } from "./SelectTimeOptionStyle";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AVAILABILITY } from '../../graphql/query/availability';
import { Dropdown, Label, Option, useId, Button, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { CREATE_RESERVATION, DELETE_RESERVATION } from "../../graphql/mutation/reservation";
import { useEffect, useState } from "react";
import { BookingPressure } from "../../__generated__/graphql";
import { useCountdown } from "../../core/hooks/useCountdown";
import { Circle24Filled, CalendarCancel24Regular } from "@fluentui/react-icons";
import AppointmentConfirmation from "../AppointmentConfirmation/AppointmentConfirmation";
import AppointmentInputComponent from "../AppointmentInputComponent/AppointmentInputComponent";

export declare type SelectTimeOptionProps = {
    date: Date | null,
    onSelectionChanged(reservation: ReservationModel | null): void
}

export declare type ReservationModel = {
    start: string,
    end: string,
    reservationCode: string,
    validTo: string,
    reservationState: string,
}

function toDate(date: Date | null): string {
    if (date === null)
        return '';
    return new Date(date).toJSON();
}

function toPretty(date: Date | any | null): string {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

function SelectTimeOption(props: SelectTimeOptionProps) {
    const comboId = useId("combo-multi");
    const style = useSelectSupportOptionButtonStyle();
    const { onSelectionChanged, date } = props;

    const [reservationModel, setReservationModel] = useState<ReservationModel | null>(null);
    const [selectedTime, setSelectedTime] = useState<Date | null>(null);
    const [data, setData] = useState<ViewModel[]>([]);
    const [encryptedInput, setEncryptedInput] = useState<string | undefined>(undefined);

    const [reservationConfirmed, setReservationConfirmed] = useState<boolean>(false);


    const { loading: loadingQuery, data: dataQuery, error: errorQuery } = useQuery(
        GET_AVAILABILITY,
        {
            variables: { dateOnly: toDate(date), first: 100 },
            fetchPolicy: 'no-cache',
            pollInterval: 30 * 1000
        }
    );

    useEffect(() => {
        setSelectedTime(null);
        setData([]);
        if (reservationModel !== null) {
            deleteReservation({ variables: { reservationCode: reservationModel.reservationCode } })
                .then(value => {
                    //console.log(value);
                })
                .catch((e) => {
                    //console.error(e);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useEffect(() => {
        if (!loadingQuery && !errorQuery && dataQuery) {
            const items = dataQuery.availability.availability?.edges?.map<ViewModel>(i => {
                return {
                    key: i.node.start.toString(),
                    bookingPressure: i!.node.bookingPressure,
                    end: i!.node.end,
                    start: i!.node.start,
                    startText: toPretty(i.node.start)
                };
            });
            setData(items ?? []);
            //console.log("times updated");
        }
        else if (errorQuery) {
            setData([]);
        }
    }, [loadingQuery, dataQuery, errorQuery])

    const [timeLeft, startCountdown, stopCountdown] = useCountdown();

    const [createReservation, { loading: loadingCreate }] = useMutation(CREATE_RESERVATION);
    const [deleteReservation, { loading: loadingDelete }] = useMutation(DELETE_RESERVATION);

    useEffect(() => {
        if (timeLeft.elapsed && reservationModel !== null) {
            const reservationCode = reservationModel.reservationCode;
            setReservationModel(null);
            onSelectionChanged(null);

            deleteReservation({ variables: { reservationCode: reservationCode } })
                .then(value => {
                    //console.log(value);
                })
                .catch((e) => {
                    console.error(e);
                });
        };
    }, [timeLeft, reservationModel, deleteReservation, onSelectionChanged]);

    return (
        <div className={style.container}>

            {(loadingQuery) && (
                <Skeleton>
                    <div className={style.skeletonItem}>
                        <SkeletonItem size={36} animation="pulse">
                        </SkeletonItem>
                        {/* <Spinner labelPosition="after" label="Loading..." /> */}
                    </div>
                </Skeleton>
            )}

            {!loadingQuery && (
                <div className={style.item}>
                    <Label hidden={true} id={comboId}></Label>
                    <Dropdown
                        {...dropDownStyle}
                        disabled={reservationConfirmed || loadingCreate || loadingDelete || loadingQuery}
                        aria-labelledby={comboId}
                        multiselect={false}
                        onOptionSelect={(ev, data) => {
                            stopCountdown();

                            if (reservationModel !== null) {
                                deleteReservation({ variables: { reservationCode: reservationModel.reservationCode } })
                                    .then(value => {
                                        //console.log(value);
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                    });
                            }

                            const value = new Date(data.optionValue!);
                            setSelectedTime(value);

                            createReservation({ variables: { start: value.toJSON() ?? '' } })
                                .then(value => {
                                    const model = {
                                        start: value?.data?.createReservation.start ?? '',
                                        end: value?.data?.createReservation.end ?? '',
                                        reservationCode: value?.data?.createReservation.reservationCode ?? '',
                                        reservationState: value?.data?.createReservation.reservationState ?? '',
                                        validTo: value?.data?.createReservation.validTo ?? ''
                                    }
                                    setReservationModel(model);
                                    startCountdown(model.validTo);
                                    onSelectionChanged(model);
                                    //console.log(model);

                                })
                                .catch((e) => {
                                    setReservationModel(null);
                                    stopCountdown();
                                    onSelectionChanged(null);
                                    console.error(e);
                                });
                        }}
                        placeholder={data.length > 0 ? 'Available times' : 'No availability'}>
                        {data && data.map(option => (
                            <Option disabled={option.bookingPressure === BookingPressure.Closed} key={option.key} value={option.key} text={option.startText} >
                                <span>{option.startText}</span>
                                <Circle24Filled primaryFill={option.bookingPressure}></Circle24Filled>
                            </Option>
                        ))}
                        {data && data.length === 0 && (
                            <Option disabled={true} key={'closed'} value={'closed'}>
                                'No availability'
                            </Option>
                        )}
                    </Dropdown>
                </div>
            )
            }

            {
                selectedTime && (
                    <>
                        <div className={style.item}>
                            <span>{reservationConfirmed ? 'Confirmed' : timeLeft.text}</span>
                        </div>
                        <div className={style.item}>
                            <AppointmentInputComponent disabled={timeLeft.elapsed || reservationConfirmed} onCompleted={(value) => {
                                setEncryptedInput(value);
                            }} />
                        </div>
                        <div className={style.controlButtons}>

                            <div className={style.item}>
                                <Button
                                    disabled={reservationConfirmed || reservationModel === null}
                                    icon={<CalendarCancel24Regular />}
                                    onClick={() => {

                                        if (reservationModel !== null) {
                                            const reservationCode = reservationModel.reservationCode;
                                            setReservationModel(null);
                                            deleteReservation({ variables: { reservationCode: reservationCode } })
                                                .then(value => {
                                                    // console.log(value);
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                })
                                                .finally(() => {
                                                    stopCountdown();
                                                    setSelectedTime(null);
                                                });
                                        }
                                    }}
                                    {...cancelButtonProps}>
                                    Cancel
                                </Button>
                            </div>
                            <div className={style.item}>
                                <AppointmentConfirmation payload={encryptedInput} disabled={encryptedInput === null} reservationModel={reservationModel} onReservationConfirmed={(value) => {
                                    setReservationConfirmed(true);
                                    stopCountdown();
                                }} />
                                {/* <Button
                                    onClick={() => {
                                        if (reservationModel !== null) {
                                            const reservationCode = reservationModel.reservationCode;
                                            setReservationModel(null);
                                            createAppointment({ variables: { reservationCode: reservationModel.reservationCode } })
                                                .then(value => {
                                                    onReservationConfirmed(reservationModel);
                                                    console.log(value);
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                    onReservationConfirmed(null);
                                                });
                                        }
                                    }}
                                    icon={<Checkmark24Filled />}
                                    disabled={timeLeft.elapsed}
                                    {...confirmButtonProps}>
                                    Confirm
                                </Button> */}
                            </div>
                        </div>

                    </>
                )
            }

        </div >
    )
}

export default SelectTimeOption;

type ViewModel = {
    key: string,
    bookingPressure: BookingPressure,
    end: Date,
    start: Date,
    startText: string
}