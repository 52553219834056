import { makeStyles, shorthands } from "@fluentui/react-components";

export const useInputSupportTicketComponentStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "10px", "10px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    container2: {
        gridRowStart: '1',

        backgroundColor: 'transparent',
        display: "inline-flex",
        flexDirection: 'row',
        alignItems: 'end',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },

    infoText: {
        gridColumnStart: '1',
        gridColumnEnd: '3',
        gridRowStart: '2'
    },

    input: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.borderRadius('0px'),

    },

    inputText: {
        ...shorthands.borderRight('none'),
        ...shorthands.borderLeft('1px', 'solid', 'rgb(240, 240, 240)'),
        ...shorthands.borderTop('1px', 'solid', 'rgb(240, 240, 240)'),
        ...shorthands.borderBottom('1px', 'solid', 'rgb(240, 240, 240)'),
        ...shorthands.borderRadius('0px'),
    },
});
