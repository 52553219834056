import { useEffect, useRef, useState } from "react";

export function useCountdown() {

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(null));
    const intervalRef = useRef<NodeJS.Timer>(); // Add a ref to store the interval id

    const startCountdown = (date: Date) => {
        setTimeLeft(calculateTimeLeft(date));

        intervalRef.current = setInterval(() => {
            setTimeLeft(() => calculateTimeLeft(date));
        }, 1000);
    };

    const stopCountdown = () => clearInterval(intervalRef.current);

    // Add a listener to `timeLeft`
    useEffect(() => {
        if (!timeLeft.difference || timeLeft.difference <= 0) {
            clearInterval(intervalRef.current);
        }
    }, [timeLeft]);

    return [timeLeft, startCountdown, stopCountdown] as const
}

const calculateTimeLeft = (date: Date | null) => {
    if (date === null) return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        difference: 1,
        elapsed: false,
        text: "",
    };

    let difference = +new Date(date) - +new Date();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return difference > 0 ?
        {
            days,
            hours,
            minutes,
            seconds,
            difference,
            elapsed: false,
            text: prettyPrintCountDownString(days, hours, minutes, seconds, difference),
        } : {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            difference: 0,
            elapsed: true,
            text: prettyPrintCountDownString(days, hours, minutes, seconds, difference),
        }
}

function prettyPrintCountDownString(
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    difference: number
) {
    if (difference > 0) {
        if (days && days >= 0) return `${days.toString().padStart(2, '0')} days, ${hours!.toString().padStart(2, '0')} hours, ${minutes!.toString().padStart(2, '0')} minutes and ${seconds!.toString().padStart(2, '0')} seconds`;
        if (hours && hours >= 0) return `${hours.toString().padStart(2, '0')} hours, ${minutes!.toString().padStart(2, '0')} minutes and ${seconds!.toString().padStart(2, '0')} seconds`;
        if (minutes && minutes >= 0) return `${minutes.toString().padStart(2, '0')} minutes and ${seconds!.toString().padStart(2, '0')} seconds`;
        if (seconds && seconds >= 0) return `${seconds.toString().padStart(2, '0')} seconds`;
    }

    return "The time has elapsed";
}