import { Body1, Button, Spinner } from "@fluentui/react-components";
import {
    ArrowCounterclockwise24Filled
} from "@fluentui/react-icons";
import { useErrorControlStyle } from "./ErrorControlStyle";
import { ApolloError } from "@apollo/client";
import { useState } from "react";

export declare type ErrorControlProps = {
    error: ApolloError,
    onRetry(): Promise<boolean>

}

function ErrorControl(props: ErrorControlProps) {
    const { error, onRetry } = props;

    const style = useErrorControlStyle();

    const [retry, setRetry] = useState<boolean>(false);

    return (
        <div className={style.container}>
            <div className={style.title}>
                {retry && (
                    <Spinner />
                )}

                {!retry && (
                    <Body1>{error.message}</Body1>
                )}
            </div>
            <div className={style.item}>
                <Button
                    disabled={retry}
                    onClick={() => {
                        setRetry(true);
                        onRetry().finally(() => {
                            setRetry(false);
                        });
                    }}
                    icon={<ArrowCounterclockwise24Filled />}>Retry</Button>
            </div>

        </div >
    )
}

export default ErrorControl;