import { VideoStreamOptions } from "@azure/communication-react";
import { makeStyles, shorthands } from "@fluentui/react-components";

export const useVideoComponentStyle = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        alignContent: 'center',
        // backgroundColor: 'blue',
        // display: "inline-grid",
        // alignItems: 'stretch',
        // gridTemplateColumns: "repeat(1, 1fr)",
        // gridTemplateRows: "repeat(1, 1fr)",
        ...shorthands.gap("5px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.overflow('auto')
    },
    item: {

    }
    // videoContainer: {
    //     overflow: 'auto'
    // }
});

export const localVideoViewOptions = {
    scalingMode: 'Fit',
    isMirrored: true
} as VideoStreamOptions;

export const remoteVideoViewOptions = {
    scalingMode: 'Fit',
    isMirrored: true
} as VideoStreamOptions;
