import { buttonStyle, centerNavigationButtonSkeletonStyle, leftNavigationButtonSkeletonStyle, leftNavigationButtonStyle, rightNavigationButtonSkeletonStyle, rightNavigationButtonStyle, useSelectSupportOptionButtonStyle } from "./SelectDayOptionStyle";
import { useQuery } from "@apollo/client";
import { GET_AVAILABILITY_DAYS } from '../../graphql/query/availabilityDay';
import { Button, Label, Skeleton, SkeletonItem, ToggleButton } from "@fluentui/react-components";
import React from "react";
import {
    ArrowLeft24Regular, ArrowRight24Regular
} from "@fluentui/react-icons";
import ErrorControl from "../ErrorControl/ErrorControl";

export declare type SelectDayOptionProps = {
    date?: Date,
    days?: number,
    onSelectionChanged(value: { day: Date | null }): void
}

function SelectDayOption(props: SelectDayOptionProps) {

    const style = useSelectSupportOptionButtonStyle();
    const { onSelectionChanged, days, date } = props;

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [startDate, setStartDate] = React.useState<Date | null>(date ?? new Date());
    const [availableDays, SetAvailableDays] = React.useState<Date[] | null>(null);

    const { loading, error, data, refetch } = useQuery(
        GET_AVAILABILITY_DAYS, { variables: { start: toDate(startDate!), days: days ?? 3 } }
    );

    React.useEffect(() => {
        if (!loading && !error && data) {
            const days = data.availabilityDayView.availabilityDays?.edges?.map(i => new Date(i.node.day));
            SetAvailableDays(days ?? null);
        }
        else if (error) {
            SetAvailableDays(null);
        }
    }, [loading, data, error]);

    const navigationButtonClicked = React.useCallback(
        (buttonIndex: number) => {
            setSelectedDate(null);
            onSelectionChanged({ day: null });
            switch (buttonIndex) {
                case 1:
                    // left arrow
                    if (startDate) {
                        const next = new Date(startDate.setDate(startDate.getDate() - (days ?? 3)));
                        setStartDate(next);
                    }
                    break;
                case 2:
                    // right arrow
                    if (startDate) {
                        const next = new Date(startDate.setDate(startDate.getDate() + (days ?? 3)));
                        setStartDate(next);
                    }
                    break;
            }
        }, [startDate, days, setSelectedDate, onSelectionChanged]
    );

    if (error) return (
        <div className={style.container}>
            <div className={style.item}>
                <ErrorControl error={error} onRetry={async () => {
                    try {
                        const result = await refetch({ start: toDate(startDate!), days: days ?? 3 });
                        return !result.errors;
                    } catch (error) {
                        return false;
                    }
                }} />
            </div>
        </div>
    );

    const isLoading = loading;
    // use Skeleton https://react.fluentui.dev/?path=/docs/components-skeleton--default

    return (
        <div className={style.container}>


            {(isLoading) && (
                <div className={style.item}>
                    <Skeleton>
                        <div className={style.skeletonControl}>
                            <div className={style.skeletonItem} style={{ width: '40px' }}>
                                <SkeletonItem {...leftNavigationButtonSkeletonStyle} />
                            </div>
                            <div className={style.skeletonItem} style={{ width: '96px' }}>
                                <SkeletonItem {...centerNavigationButtonSkeletonStyle} />
                            </div>
                            <div className={style.skeletonItem} style={{ width: '96px' }}>
                                <SkeletonItem {...centerNavigationButtonSkeletonStyle} />
                            </div>
                            <div className={style.skeletonItem} style={{ width: '96px' }}>
                                <SkeletonItem {...centerNavigationButtonSkeletonStyle} />
                            </div>
                            <div className={style.skeletonItem} style={{ width: '40px' }}>
                                <SkeletonItem {...rightNavigationButtonSkeletonStyle} />
                            </div>
                        </div>
                    </Skeleton>
                </div>
            )}

            {/* {
                (isLoading) && (
                    <>
                        <div className={style.item}>
                            <Spinner {...spinnerStyle} />
                        </div>
                    </>
                )
            } */}

            {/* {data && data.availabilityDays.availabilityDays?.edges?.map(item => ( */}

            {(!isLoading) && (
                <div className={style.item}>
                    <div className={style.control}>
                        <Button
                            disabled={(compareDateOnly(new Date(), startDate ?? new Date()) >= 0)}
                            onClick={() => navigationButtonClicked(1)}
                            icon={<ArrowLeft24Regular />}
                            {...leftNavigationButtonStyle}
                        />
                    </div>
                </div>
            )}

            {(!isLoading) && availableDays !== null && availableDays?.map(day => (

                <div key={day!.toDateString()} className={style.item}>
                    <div className={style.control}>
                        <Label>
                            {day.toLocaleDateString([], { weekday: 'short' })}
                        </Label>
                        <ToggleButton
                            {...buttonStyle}
                            appearance={selectedDate && day === selectedDate ? 'primary' : 'secondary'}
                            checked={selectedDate !== null && day === selectedDate}
                            onClick={() => {
                                setSelectedDate(day);
                                onSelectionChanged({ day });
                            }}>
                            {day.toLocaleDateString([], { day: '2-digit' })}
                        </ToggleButton>
                        <Label>
                            {day.toLocaleDateString([], { month: 'short' })}
                        </Label>
                    </div>
                </div>
            ))
            }

            {(!isLoading) && (
                <div className={style.item}>
                    <div className={style.control}>
                        <Button
                            onClick={() => navigationButtonClicked(2)}
                            icon={<ArrowRight24Regular />}
                            {...rightNavigationButtonStyle}
                        />
                    </div>
                </div>
            )}
        </div >
    )
}

export default SelectDayOption;

function toDate(date: Date): string {
    const d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T00:00:00.000Z`;
    return d;
}

function compareDateOnly(date1: Date, date2: Date): number {
    var d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()).valueOf();
    var d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()).valueOf();
    if (d1 > d2) return 1;
    else if (d1 < d2) return -1;
    else return 0
}
