import { useEffect, useMemo, useState } from "react";
import { useSharingComponentStyle } from "./SharingComponentStyle";
import ControlBarComponent from "./ControlBarComponent/ControlBarComponent";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import { initializeIcons } from '@fluentui/react';
import { registerIcons } from '@fluentui/react';
import { CallAgentProvider, CallClientProvider, CallProvider, DEFAULT_COMPONENT_ICONS, StatefulCallClient, createStatefulCallClient } from '@azure/communication-react';
import VideoComponent from "./VideoComponent/VideoComponent";
import { Divider } from "@fluentui/react-components";
import { GET_SCREEN_SHARING_SESSION } from "../../graphql/query/screenSharingSession";
import { useLazyQuery } from "@apollo/client";
import { Call, CallAgent } from "@azure/communication-calling";
import InputSupportTicketComponent from "./InputSupportTicketComponent/InputSupportTicketComponent";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

export declare type SelectSupportOptionButtonProps = {
    onSessionStarted(): void
    onSessionEnded(): void
}

function SelectSupportOptionButton(props: SelectSupportOptionButtonProps) {
    const { onSessionEnded, onSessionStarted } = props;
    const style = useSharingComponentStyle();
    const displayName = 'user';
    const [concentAccepted, setConcentAccepted] = useState<boolean>(false);
    const [statefulCallClient, setStatefulCallClient] = useState<StatefulCallClient>();
    const [callAgent, setCallAgent] = useState<CallAgent>();
    const [call, setCall] = useState<Call>();
    const [callEnded, setCallEnded] = useState(false);

    const [getScreenSharingSession, { loading, error, data }] = useLazyQuery(GET_SCREEN_SHARING_SESSION);

    if (error) {
        console.error(error.name);
    }

    const tokenCredential = useMemo(() => {
        if (data?.screenSharingSession?.accessToken?.token) {
            return new AzureCommunicationTokenCredential(data?.screenSharingSession.accessToken.token);
        }
    }, [data?.screenSharingSession?.accessToken?.token]);


    useEffect(() => {
        if (callEnded) {
            callAgent?.dispose().catch(e => { });
            setCall(undefined);
            setCallAgent(undefined);
            setStatefulCallClient(undefined);
            onSessionEnded();
        }
    }, [callEnded, callAgent, onSessionEnded, call]);

    useEffect(() => {
        if (!loading && data && !error && concentAccepted) {
            const value = createStatefulCallClient({
                userId: { communicationUserId: data.screenSharingSession.accessToken?.userId ?? '' }
            });
            setStatefulCallClient(value);
        }
    }, [loading, data, concentAccepted, error]);

    useEffect(() => {
        if (callAgent === undefined && statefulCallClient && tokenCredential) {
            const createCallAgent = async (): Promise<void> => {
                try {
                    setCallAgent(await statefulCallClient.createCallAgent(tokenCredential, { displayName: displayName }));
                } catch (error) {
                }
            };

            createCallAgent();
        }
    }, [callAgent, statefulCallClient, tokenCredential, displayName, call]);

    useEffect(() => {
        if (call === undefined && callAgent !== undefined && data) {
            setCall(callAgent.join({ groupId: data.screenSharingSession.groupId }, { audioOptions: { muted: true, localAudioStreams: undefined } }));
        }

    }, [callAgent, data, call]);
    return (
        <div className={style.container}>
            {/* {!concentAccepted && (
                <>
                    <Button onClick={() => setCallEnded(true)}>Cancel</Button>
                </>
            )} */}
            {!concentAccepted && (
                <InputSupportTicketComponent readonly={false} onChanged={(value) => {
                    getScreenSharingSession({ fetchPolicy: 'network-only', variables: { passKey: value } }).then(v => {
                        if (v.data?.screenSharingSession.accessToken?.token) {
                            setConcentAccepted(true);
                            onSessionStarted();
                        }
                    }).catch(e => {
                        setConcentAccepted(false);
                    });
                }} />
            )}
            {/* {(!concentAccepted || true) && (
                <div>
                    <ConcentComponent onChanged={accept => { setConcentAccepted(accept); onSessionStarted(); }} />
                </div>
            )} */}
            {!callEnded && (
                <>
                    {(concentAccepted && !loading && data && statefulCallClient) && (
                        <CallClientProvider callClient={statefulCallClient}>
                            {callAgent && (
                                <CallAgentProvider callAgent={callAgent}>
                                    {call && (<CallProvider call={call}>

                                        <div className={style.menu}>
                                            <ControlBarComponent
                                                call={call}
                                                onCallEnded={() => setCallEnded(true)}
                                            />
                                        </div>
                                        <div className={style.item}>
                                            <Divider inset />
                                        </div>
                                        <div className={style.content}>
                                            <VideoComponent />
                                        </div>
                                    </CallProvider>)}

                                </CallAgentProvider>)}
                        </CallClientProvider>
                    )}
                </>
            )}





        </div >
    )
}

export default SelectSupportOptionButton;
