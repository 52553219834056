import { useVideoComponentStyle } from "./VideoComponentStyle";
import { VideoGallery, usePropsFor } from "@azure/communication-react";

export declare type VideoComponentProps = {
    limitedOptions?: boolean
}

// https://azure.github.io/communication-ui-library/?path=/story/overview--page
function VideoComponent(props: VideoComponentProps) {
    // const { _ } = props;

    const style = useVideoComponentStyle();

    const videoGalleryProps = usePropsFor(VideoGallery);

    return (
        <div className={style.container}>

            <VideoGallery

                {...videoGalleryProps}

                showMuteIndicator={false} />
        </div >
    )
}

export default VideoComponent;