import { gql } from "../../__generated__/gql";

export const GET_SCREEN_SHARING_SESSION = gql(/* GraphQL */ `
  query screenSharingSession($passKey: String!) {
    screenSharingSession(passKey: $passKey){
      passKey,
      groupId,
      accessToken {
        token,
        userId,
        expiresOn
      }
    }
  }
`); 