import { ButtonProps, makeStyles, shorthands } from "@fluentui/react-components";

export const useAppointmentConfirmation = makeStyles({
    container: {
    },
    item: {
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
    },
    controlButtons: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        alignItems: 'center',
        flexDirection: 'row',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "0px"),
    },

});

export const confirmButtonProps: ButtonProps = {
    appearance: 'primary',
    iconPosition: 'after',
    size: 'large',
};