import { ToggleButton } from "@fluentui/react-components";
import { leftButton, middleButton, rightButton, useSelectSupportOptionButtonStyle } from "./SelectSupportOptionButtonStyle";
import React from "react";
import {
    bundleIcon,
    CalendarMonthFilled,
    CalendarMonthRegular,
    HeadphonesFilled,
    HeadphonesRegular,
    // ShareScreenStartFilled,
    // ShareScreenStartRegular,
    SquareArrowForwardRegular,
    SquareArrowForwardFilled

} from "@fluentui/react-icons";

const CalendarMonth = bundleIcon(CalendarMonthFilled, CalendarMonthRegular);
export type SupportOption = 'now' | 'schedule' | 'sharing' | 'activeSharingSession';

export declare type SelectSupportOptionButtonProps = {
    onSelectionChanged(value: SupportOption): void
}

function SelectSupportOptionButton(props: SelectSupportOptionButtonProps) {

    const { onSelectionChanged } = props;

    const style = useSelectSupportOptionButtonStyle();
    const [checked1, setChecked1] = React.useState(true);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);

    const toggleChecked = React.useCallback(
        (buttonIndex: number) => {
            switch (buttonIndex) {
                case 1:
                    setChecked1(true);
                    setChecked2(false);
                    setChecked3(false);

                    onSelectionChanged('now');
                    break;
                case 2:
                    setChecked2(true);
                    setChecked1(false);
                    setChecked3(false);

                    onSelectionChanged('schedule');
                    break;
                case 3:
                    setChecked3(true);

                    setChecked2(false);
                    setChecked1(false);
                    onSelectionChanged('sharing');
                    break;
            }
        },
        [onSelectionChanged]
    );


    return (
        <div className={style.container}>
            <ToggleButton
                {...leftButton}

                className={style.left}
                appearance={checked1 ? 'primary' : 'secondary'}
                icon={checked1 ? <HeadphonesFilled /> : <HeadphonesRegular />}
                checked={checked1}
                onClick={() => toggleChecked(1)}>
                Now
            </ToggleButton>

            <ToggleButton
                {...middleButton}

                className={style.middle}
                appearance={checked3 ? 'primary' : 'secondary'}
                icon={checked3 ? <SquareArrowForwardFilled /> : <SquareArrowForwardRegular />}
                checked={checked3}
                onClick={() => toggleChecked(3)}>
                &nbsp;
            </ToggleButton>

            <ToggleButton
                {...rightButton}

                className={style.right}
                appearance={checked2 ? 'primary' : 'secondary'}
                icon={checked2 ? <CalendarMonth /> : <CalendarMonthRegular />}
                checked={checked2}
                onClick={() => toggleChecked(2)}>
                Schedule
            </ToggleButton>

        </div >
    )
}

export default SelectSupportOptionButton;
