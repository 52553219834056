import { ControlBarProps, EndCallButtonProps, ScreenShareButtonProps } from "@azure/communication-react";
import { makeStyles, shorthands } from "@fluentui/react-components";

export const useControlBarComponentStyle = makeStyles({
    container: {
        backgroundColor: 'red',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
});

export const screenShareButton: ScreenShareButtonProps =
{
    shape: 'square',
    showLabel: true,
    style: {
        borderRadius: '20px 0px 0px 20px',
        border: '1px solid #407528',
        backgroundColor: '#407528',
        color: 'white'
    },
}

export const rightButton: EndCallButtonProps =
{
    shape: 'square',
    showLabel: true,
    style: {
        borderRadius: '0px 20px 20px 0px',
        border: '1px solid rgb(164, 46, 67)'
    }
}

export const raiseHandCenterModeButton: ScreenShareButtonProps =
{
    shape: 'square',
    showLabel: true,
    style: {
        borderRadius: '0px 0px 0px 0px',
        borderTop: '1px solid lightgray',
        borderBottom: '1px solid lightgray'
    }
}

export const raiseHandLeftModeButton: ScreenShareButtonProps =
{
    shape: 'square',
    showLabel: true,
    style: {
        borderRadius: '20px 0px 0px 20px',
        border: '1px solid lightgray',
    },
}

export const controlBar: ControlBarProps =
{
    styles: {
        root: {
            marginTop: '0px',
            paddingTop: '0px'
        }
    }

}