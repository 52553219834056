import { LargeTitle } from "@fluentui/react-components";
import {
    Headphones48Regular,
    Calendar48Regular,
    SquareArrowForward48Regular
} from "@fluentui/react-icons";
import { useSupportOptionHeaderStyle } from "./SupportOptionHeaderStyle";

export declare type SelectSupportOptionButtonProps = {
    state: string
}

function SelectSupportOptionButton(props: SelectSupportOptionButtonProps) {
    const { state } = props;

    const style = useSupportOptionHeaderStyle();

    return (
        <div className={style.container}>

            {(!state || state === 'now') && (
                <>
                    <div className={style.above}>
                        <Headphones48Regular />
                    </div>
                    <div className={style.under}>
                        <LargeTitle>Call now</LargeTitle>
                    </div>
                </>
            )}

            {state === 'schedule' && (
                <>
                    <div className={style.above}>
                        <Calendar48Regular />
                    </div>
                    <div className={style.under}>
                        <LargeTitle>Schedule</LargeTitle>
                    </div>
                </>
            )}

            {(state === 'sharing' || state === 'activeSharingSession') && (
                <>
                    <div className={style.above}>
                        <SquareArrowForward48Regular />
                    </div>
                    <div className={style.under}>
                        <LargeTitle>Share</LargeTitle>
                    </div>
                </>
            )}

        </div >
    )
}

export default SelectSupportOptionButton;