import { ButtonProps, makeStyles, shorthands } from "@fluentui/react-components";

export const useSelectSupportOptionButtonStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "inline-grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(1, 1fr)",
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "5px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    left: {
        gridRowStart: '1',
        gridColumnStart: '1',
        // ...shorthands.borderRadius('15px', '0px', '0px', '15px')
    },
    right: {
        gridRowStart: '1',
        gridColumnStart: '3',
        // ...shorthands.borderRadius('0px', '15px', '15px', '0px')
    },
    middle: {
        gridRowStart: '1',
        gridColumnStart: '2',
        // ...shorthands.borderRadius('0px', '15px', '15px', '0px')
    }
});

export const leftButton: ButtonProps =
{
    iconPosition: "before",
    shape: 'square',
    size: 'large',
    style: { borderRadius: '10px 0px 0px 10px' },
}

export const rightButton: ButtonProps =
{
    iconPosition: "after",
    shape: 'square',
    size: 'large',
    style: { borderRadius: '0px 10px 10px 0px' }
}

export const middleButton: ButtonProps =
{
    iconPosition: "after",
    shape: 'square',

    size: 'large',
    style: {
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px 0px 0px 0px'
    }
} 