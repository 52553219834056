import { Body1, Body2, Title2 } from "@fluentui/react-components";
import {
    Phone48Regular
} from "@fluentui/react-icons";
import { useCallNowOptionBannerStyle } from "./CallNowOptionBannerStyle";

export declare type SelectSupportOptionButtonProps = {
}

function CallNowOptionBanner(props: SelectSupportOptionButtonProps) {

    const style = useCallNowOptionBannerStyle();

    return (
        <div className={style.container}>
            <div className={style.title}>
                <Body1>Wait time: </Body1>
                <Body2>10 minutes or more</Body2>
            </div>
            <div className={style.contactBanner}>
                <div className={style.logo}>
                    <Phone48Regular />
                </div>
                <div className={style.phone}>
                    <Title2>+45 12 34 56 78</Title2>
                </div>
            </div>

        </div >
    )
}

export default CallNowOptionBanner;
