import './../../App.css';

import { useState } from 'react';
import { useStyles } from './AppPageStyle';
import SelectSupportOptionButton, { SupportOption } from '../../Components/SelectSupportOptionButton/SelectSupportOptionButton';
import SupportOptionHeader from '../../Components/SupportOptionHeader/SupportOptionHeader';
import SelectDayOption from '../../Components/SelectDayOption/SelectDayOption';
import CallNowOptionBanner from '../../Components/CallNowOptionBanner/CallNowOptionBanner';
import SelectTimeOption, { ReservationModel } from '../../Components/SelectTimeOption/SelectTimeOption';
import SharingComponent from '../../Components/Sharing/SharingComponent';

function AppPage() {
  const styles = useStyles();


  const [selectedSupportOption, setSelectedSupportOption] = useState<SupportOption>('now');
  const [selectedSupportDay, setSelectedSupportDay] = useState<Date | null>(null);
  const [/*selectedSupportTime*/, setSelectedSupportTime] = useState<ReservationModel | null>(null);
  // const [reservationConfirmation, setReservationConfirmation] = useState<ReservationModel | null>(null);

  const [/*reservationCode*/, setReservationCode] = useState<string | null>(null);

  return (
    <div className="App">
      <div>
        <div className={styles.container}>

          <div className={styles.item}>
            <SupportOptionHeader state={selectedSupportOption} />
          </div>

          {selectedSupportOption !== 'activeSharingSession' && (

            <div className={styles.item}>
              <SelectSupportOptionButton onSelectionChanged={(value) => {
                setSelectedSupportOption(value);
                setSelectedSupportTime(null);
                setSelectedSupportDay(null);
                setReservationCode(null);
              }} />
            </div>
          )}

          {selectedSupportOption && selectedSupportOption === 'now' && (
            <div className={styles.item}>
              <CallNowOptionBanner />
            </div>
          )}

          {selectedSupportOption && (selectedSupportOption === 'activeSharingSession' || selectedSupportOption === 'sharing') && (
            <div className={styles.mainContentItem}>
              <SharingComponent
                onSessionStarted={() => setSelectedSupportOption('activeSharingSession')}
                onSessionEnded={() => setSelectedSupportOption('now')}
              />
            </div>
          )}

          {selectedSupportOption && selectedSupportOption === 'schedule' && (
            <div className={styles.item}>
              <SelectDayOption onSelectionChanged={(value) => {
                setSelectedSupportDay(value.day);
                setSelectedSupportTime(null);
                setReservationCode(null);
              }} />
            </div>
          )}

          {selectedSupportOption && selectedSupportOption === 'schedule' && selectedSupportDay !== null && (
            <div className={styles.item}>
              <SelectTimeOption date={selectedSupportDay} onSelectionChanged={value => {
                setSelectedSupportTime(value);
              }} />
            </div>
          )}

        </div>
      </div >
    </div >
  );
}

export default AppPage;
