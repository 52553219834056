import { makeStyles, shorthands } from "@fluentui/react-components";

export const useSharingComponentStyle = makeStyles({
    container: {
        width: "100%",
        height: "100%",
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },
    menu: {
        ...shorthands.margin("0px"),
    },
    content: {
        width: "100%",
        height: "100%",
        ...shorthands.margin("0px"),
    },
    item: {
        width: "100%",
        ...shorthands.margin("10px", "0px", "0px", "0px"),
    },
});
