import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";

import { RouterProvider } from 'react-router-dom';
import appRoutes from './Routes/Routes';

import {
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";
import { GlobalAppSettings } from './settings/GlobalAppSettings';

const { AzureLogger, setLogLevel } = require("@azure/logger");

// Set the logger's log level
setLogLevel('warning');

// Redirect log output to wherever desired. To console, file, buffer, REST API, etc...
AzureLogger.log = (...args: any[]) => {
  // console.log(...args); // Redirect log output to console
};


const additiveLink = from([
  new RetryLink({
    delay: {
      initial: 300,
      max: 800,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  }),
  new HttpLink(
    {
      uri: `https://${GlobalAppSettings.apiEndpoint}/graphql/`,
      headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Im1hcnRpbmVzbWFubiIsInN1YiI6Im1hcnRpbmVzbWFubiIsImp0aSI6IjljZWQ5OTFhIiwic2NvcGUiOiJncmVldGluZ3NfYXBpIiwicm9sZSI6ImFkbWluIiwicmFuZG9tIjoiMTIzNCIsImF1ZCI6WyJodHRwczovL2xvY2FsaG9zdDo1MjUzIiwiaHR0cDovL2xvY2FsaG9zdDo1MjUyIl0sIm5iZiI6MTY5MzYwMTg5NCwiZXhwIjoxNzAxNDY0Mjk0LCJpYXQiOjE2OTM2MDE4OTUsImlzcyI6ImRvdG5ldC11c2VyLWp3dHMifQ.EprVqFFF98XIUtGsJmOZLJVzcdxkJMDiz0Xc9WDhnkY' }
    })
]);

const client = new ApolloClient({
  cache: new InMemoryCache({


    // typePolicies: {
    //   Query: {
    //     fields: {
    //       availabilityDayView: {
    //         // Don't cache separate results based on
    //         // any of this field's arguments.
    //         //keyArgs: false,

    //         // Concatenate the incoming list items with
    //         // the existing list items.
    //         merge(existing: AvailabilityDayView = { days: 0 }, incoming: AvailabilityDayView) {

    //           console.log(incoming);
    //           //return [existing, ...incoming];
    //           //return incoming;
    //           return { existing, ...incoming, days: existing.days + incoming.days };
    //         },
    //       }
    //     }
    //   }
    // }






  }),
  link: additiveLink,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <FluentProvider theme={webLightTheme}>
    <ApolloProvider client={client}>
      <RouterProvider router={appRoutes} />
    </ApolloProvider>
  </FluentProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


////
