import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
    /* https://react.fluentui.dev/?path=/docs/concepts-developer-positioning-components--default */
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    container: {
        width: '100vw',
        height: '100vh',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "0px", "0px", "0px"),
        ...shorthands.border('0px', 'none', 'transparent')
    },

    item: {
        ...shorthands.border('1px', 'none', 'black')
    },
    mainContentItem: {
        height: '100%',
        width: '100%',

    }

});

