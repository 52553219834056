import {
    createHashRouter,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import AppPage from "./AppPage/AppPage";
import AppTestPage from "./AppTestPage/AppTestPage";

const appRoutes = createHashRouter([
    {
        path: "/",
        element: <AppPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/test",
        element: <AppTestPage />,
        errorElement: <ErrorPage />,
    },
]);

export default appRoutes