import { ButtonProps, DropdownProps, makeStyles, shorthands } from "@fluentui/react-components";

export const useSelectSupportOptionButtonStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        alignItems: 'stretch',
        flexDirection: 'column',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "0px"),
    },
    item: {
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
    },
    skeletonItem: {
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        width: '260px',
        // height: '50px',
    },
    controlButtons: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        alignItems: 'center',
        flexDirection: 'row',
        ...shorthands.gap("10px"),
        ...shorthands.margin("0px", "0px"),
    },

});

export const dropdownStyle: DropdownProps =
{
    size: 'large',
};

export const confirmButtonProps: ButtonProps = {
    appearance: 'primary',
    iconPosition: 'after',
    size: 'large',
};

export const cancelButtonProps: ButtonProps = {
    appearance: 'secondary',
    iconPosition: 'before',
    size: 'large',
};