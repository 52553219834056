import { gql } from "../../__generated__/gql";

export const GET_SETTINGS = gql(/* GraphQL */ `
  query get_settings {
    settings {
      keys {
        name
        value
      }
    }
  }
`);