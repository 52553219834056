import { useCallback, useState } from "react";
import { raiseHandCenterModeButton, raiseHandLeftModeButton, rightButton, screenShareButton, useControlBarComponentStyle } from "./ControlBarComponentStyle";
import { ControlBar, EndCallButton, RaiseHandButton, ScreenShareButton, usePropsFor } from "@azure/communication-react";
import { Call, Features } from "@azure/communication-calling";
import { utils } from "../../../core/utils";


export declare type ControlBarComponentProps = {
    limitedOptions?: boolean
    onCallEnded(): void
    call: Call
}

function ControlBarComponent(props: ControlBarComponentProps) {
    const { call, onCallEnded } = props;

    const screenShareProps = usePropsFor(ScreenShareButton);
    const isMobile = utils.isMobile();
    // const raiseHandProps = usePropsFor(RaiseHandButton);

    const endCallProps = usePropsFor(EndCallButton);

    const [callEnded, setCallEnded] = useState(false);
    const [handRaised, setHandRaised] = useState(false);

    const style = useControlBarComponentStyle();

    const onHangup = useCallback(async (): Promise<void> => {
        await endCallProps.onHangUp();
        setCallEnded(true);
        onCallEnded();
    }, [endCallProps, onCallEnded]);

    const onRaiseHand = useCallback(async (): Promise<void> => {
        if (handRaised) {
            await call.feature(Features.RaiseHand).lowerHand();
            setHandRaised(false);
        }
        else {
            await call.feature(Features.RaiseHand).raiseHand();
            setHandRaised(true);
        }

    }, [call, handRaised]);

    const raiseHandButtonStyle = isMobile ? raiseHandLeftModeButton : raiseHandCenterModeButton;

    return (
        <div className={style.container}>
            <ControlBar layout={'horizontal'}>
                {(!isMobile || true) && (
                    <ScreenShareButton {...screenShareProps} {...screenShareButton} onError={(e) => { console.log(e) }} disabled={callEnded || isMobile} />
                )}
                <RaiseHandButton disabled={callEnded} {...raiseHandButtonStyle} checked={handRaised} onToggleRaiseHand={onRaiseHand} />
                <EndCallButton {...endCallProps} {...rightButton} disabled={callEnded} onHangUp={onHangup} />
            </ControlBar>
        </div >
    )
}

export default ControlBarComponent;
