/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation appointment($reservationCode: String!, $payload: String!) {\n    createAppointment(reservationCode: $reservationCode, payload: $payload) {\n      reservationCode\n    }\n  }\n": types.AppointmentDocument,
    "\n  mutation createReservation($start: String!) {\n    createReservation(start: $start) {\n      reservationCode,\n      start,\n      end,\n      timeZone,\n      reservationState,\n      validTo,\n    }\n  }\n": types.CreateReservationDocument,
    "\n  mutation deleteReservation($reservationCode: String!) {\n    deleteReservation(reservationCode: $reservationCode) {\n      reservationCode,\n      reservationState,\n    }\n  }\n": types.DeleteReservationDocument,
    "\n  query availability($dateOnly: String!, $first: Int!) {\n    availability(dateOnly: $dateOnly) {\n      queryText \n      availability(first: $first){\n        edges{\n          node{\n            start, end, bookingPressure\n          }\n        }\n      }\n    }\n  }\n": types.AvailabilityDocument,
    "\n  query availabilityDays($start: DateTime!, $days: Int!) {\n    availabilityDayView(start: $start, days: $days) {\n      days\n      availabilityDays(first: $days) {\n        edges {\n          node {\n            day\n          }\n        }\n      }\n    }\n  }\n": types.AvailabilityDaysDocument,
    "\n  query availabilityDayViewQuery($start: DateTime!, $first: Int!, $after: String) {\n    availabilityDayView(start: $start, days: $first) {\n      days\n      availabilityDays(first: $first, after: $after) {\n        edges {\n          node {\n            id,\n            day\n          }\n        },\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n": types.AvailabilityDayViewQueryDocument,
    "\n  query screenSharingSession($passKey: String!) {\n    screenSharingSession(passKey: $passKey){\n      passKey,\n      groupId,\n      accessToken {\n        token,\n        userId,\n        expiresOn\n      }\n    }\n  }\n": types.ScreenSharingSessionDocument,
    "\n  query get_settings {\n    settings {\n      keys {\n        name\n        value\n      }\n    }\n  }\n": types.Get_SettingsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation appointment($reservationCode: String!, $payload: String!) {\n    createAppointment(reservationCode: $reservationCode, payload: $payload) {\n      reservationCode\n    }\n  }\n"): (typeof documents)["\n  mutation appointment($reservationCode: String!, $payload: String!) {\n    createAppointment(reservationCode: $reservationCode, payload: $payload) {\n      reservationCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createReservation($start: String!) {\n    createReservation(start: $start) {\n      reservationCode,\n      start,\n      end,\n      timeZone,\n      reservationState,\n      validTo,\n    }\n  }\n"): (typeof documents)["\n  mutation createReservation($start: String!) {\n    createReservation(start: $start) {\n      reservationCode,\n      start,\n      end,\n      timeZone,\n      reservationState,\n      validTo,\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteReservation($reservationCode: String!) {\n    deleteReservation(reservationCode: $reservationCode) {\n      reservationCode,\n      reservationState,\n    }\n  }\n"): (typeof documents)["\n  mutation deleteReservation($reservationCode: String!) {\n    deleteReservation(reservationCode: $reservationCode) {\n      reservationCode,\n      reservationState,\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query availability($dateOnly: String!, $first: Int!) {\n    availability(dateOnly: $dateOnly) {\n      queryText \n      availability(first: $first){\n        edges{\n          node{\n            start, end, bookingPressure\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query availability($dateOnly: String!, $first: Int!) {\n    availability(dateOnly: $dateOnly) {\n      queryText \n      availability(first: $first){\n        edges{\n          node{\n            start, end, bookingPressure\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query availabilityDays($start: DateTime!, $days: Int!) {\n    availabilityDayView(start: $start, days: $days) {\n      days\n      availabilityDays(first: $days) {\n        edges {\n          node {\n            day\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query availabilityDays($start: DateTime!, $days: Int!) {\n    availabilityDayView(start: $start, days: $days) {\n      days\n      availabilityDays(first: $days) {\n        edges {\n          node {\n            day\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query availabilityDayViewQuery($start: DateTime!, $first: Int!, $after: String) {\n    availabilityDayView(start: $start, days: $first) {\n      days\n      availabilityDays(first: $first, after: $after) {\n        edges {\n          node {\n            id,\n            day\n          }\n        },\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query availabilityDayViewQuery($start: DateTime!, $first: Int!, $after: String) {\n    availabilityDayView(start: $start, days: $first) {\n      days\n      availabilityDays(first: $first, after: $after) {\n        edges {\n          node {\n            id,\n            day\n          }\n        },\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query screenSharingSession($passKey: String!) {\n    screenSharingSession(passKey: $passKey){\n      passKey,\n      groupId,\n      accessToken {\n        token,\n        userId,\n        expiresOn\n      }\n    }\n  }\n"): (typeof documents)["\n  query screenSharingSession($passKey: String!) {\n    screenSharingSession(passKey: $passKey){\n      passKey,\n      groupId,\n      accessToken {\n        token,\n        userId,\n        expiresOn\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query get_settings {\n    settings {\n      keys {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query get_settings {\n    settings {\n      keys {\n        name\n        value\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;