import { gql } from "../../__generated__/gql";

export const GET_AVAILABILITY_DAYS = gql(/* GraphQL */ `
  query availabilityDays($start: DateTime!, $days: Int!) {
    availabilityDayView(start: $start, days: $days) {
      days
      availabilityDays(first: $days) {
        edges {
          node {
            day
          }
        }
      }
    }
  }
`);

export const GET_AVAILABILITY_DAYS_QUERY = gql(/* GraphQL */ `
  query availabilityDayViewQuery($start: DateTime!, $first: Int!, $after: String) {
    availabilityDayView(start: $start, days: $first) {
      days
      availabilityDays(first: $first, after: $after) {
        edges {
          node {
            id,
            day
          }
        },
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`);