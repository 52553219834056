import { makeStyles, shorthands } from "@fluentui/react-components";

export const useAppointmentInputComponentStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "flex",
        alignItems: 'stretch',
        flexDirection: 'column',
        ...shorthands.gap("15px"),
        ...shorthands.margin("15px", "0px"),
    },
    item: {
        ...shorthands.margin("0px", "0px"),

    },

    control: {
        backgroundColor: 'transparent',
        display: "flex",
        alignItems: 'stretch',
        flexDirection: 'column',
        ...shorthands.gap("0px"),
        ...shorthands.margin("0px", "0px"),
    },

});
