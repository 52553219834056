import { gql } from "../../__generated__/gql";

export const GET_AVAILABILITY = gql(/* GraphQL */ `
  query availability($dateOnly: String!, $first: Int!) {
    availability(dateOnly: $dateOnly) {
      queryText 
      availability(first: $first){
        edges{
          node{
            start, end, bookingPressure
          }
        }
      }
    }
  }
`);