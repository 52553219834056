import { useQuery } from '@apollo/client';
import { GET_AVAILABILITY_DAYS_QUERY } from '../../graphql/query/availabilityDay';
import './../../App.css';

import { useStyles } from './AppTestPageStyle';
import { Button, Label } from '@fluentui/react-components';
import { AvailabilityDayViewQueryQuery } from '../../__generated__/graphql';
import { useCallback } from 'react';

function AppPage() {
  const styles = useStyles();

  const { loading, error, data, fetchMore } = useQuery(
    GET_AVAILABILITY_DAYS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { start: '2023-10-09', first: 3 }
  });

  const navigationButtonClicked = useCallback((buttonIndex: number) => {
    fetchMore({
      variables: {
        nextFetchPolicy: 'cache-and-network',
        fetchPolicy: 'cache-and-network',
        start: '2023-10-09',
        first: 3,
        after: data?.availabilityDayView.availabilityDays?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult, variables }) => {
        console.log(variables);
        const edges = fetchMoreResult;
        if (edges.availabilityDayView.availabilityDays?.pageInfo.endCursor === prev.availabilityDayView.availabilityDays?.pageInfo.endCursor) {
          console.log("pre-return");
          return prev;
        }

        const prevCount = prev.availabilityDayView.availabilityDays?.edges?.length ?? 0;
        const fetchMoreResultCount = edges.availabilityDayView.availabilityDays?.edges!.length ?? 0;
        const days = prevCount + fetchMoreResultCount;
        const spread: AvailabilityDayViewQueryQuery = {
          ...edges,
          availabilityDayView: {
            ...edges.availabilityDayView,
            days: days,
            availabilityDays: {
              ...edges.availabilityDayView.availabilityDays!,
              edges: [
                ...prev.availabilityDayView.availabilityDays?.edges!,
                ...edges.availabilityDayView.availabilityDays?.edges!]
            },
          }
        }
        console.log(spread.availabilityDayView.days);
        return spread;
      }
    }).then(v => {
      console.log(v);
    }).catch(e => {
      console.log(e);
    });
  }, [data?.availabilityDayView.availabilityDays?.pageInfo?.endCursor, fetchMore]);

  console.log(loading);
  if (loading) return <h1>Loading</h1>
  if (error) return <h1>{error.message}</h1>

  return (
    <div className="App">
      <div>
        <div className={styles.container}>

          <div className={styles.item}>
            {data?.availabilityDayView.availabilityDays?.edges?.map(edge => edge.node).map(node => node.day + " | ")}
          </div>

          <div className={styles.item}>
            {data?.availabilityDayView.availabilityDays?.edges && data?.availabilityDayView.availabilityDays?.edges?.map(node => new Date(node.node.day)).map(day => (
              <Label key={day.toISOString()}>
                {day.toLocaleDateString([], { weekday: 'short' })}
              </Label>
            ))}
          </div>

          <div className={styles.item}>
            <Button
              disabled={loading && !data?.availabilityDayView.availabilityDays?.pageInfo?.hasNextPage}
              onClick={() => {

                if (data?.availabilityDayView.availabilityDays?.pageInfo?.hasNextPage) {
                  navigationButtonClicked(1);
                  console.log("loading:" + data?.availabilityDayView.availabilityDays?.pageInfo?.endCursor);
                  // fetchMore({
                  //   variables: {
                  //     nextFetchPolicy: 'cache-and-network',
                  //     fetchPolicy: 'cache-and-network',
                  //     start: '2023-10-09', first: 3, after: data?.availabilityDayView.availabilityDays?.pageInfo?.endCursor,
                  //   },
                  //   updateQuery: (prev, { fetchMoreResult, variables }) => {
                  //     console.log(variables);
                  //     const edges = fetchMoreResult;
                  //     if (edges.availabilityDayView.availabilityDays?.pageInfo.endCursor === prev.availabilityDayView.availabilityDays?.pageInfo.endCursor) {
                  //       console.log("pre-return");
                  //       return prev;
                  //     }

                  //     const prevCount = prev.availabilityDayView.availabilityDays?.edges?.length ?? 0;
                  //     const fetchMoreResultCount = edges.availabilityDayView.availabilityDays?.edges!.length ?? 0;
                  //     const days = prevCount + fetchMoreResultCount;
                  //     const spread: AvailabilityDayViewQueryQuery = {
                  //       ...edges,
                  //       availabilityDayView: {
                  //         ...edges.availabilityDayView,
                  //         days: days,
                  //         availabilityDays: {
                  //           ...edges.availabilityDayView.availabilityDays!,
                  //           edges: [
                  //             ...prev.availabilityDayView.availabilityDays?.edges!,
                  //             ...edges.availabilityDayView.availabilityDays?.edges!]
                  //         },
                  //       }
                  //     }
                  //     console.log(spread.availabilityDayView.days);
                  //     return spread;
                  //   }
                  // }).then(v => {
                  //   console.log(v);
                  // }).catch(e => {
                  //   console.log(e);
                  // });
                }

              }} >More: {data?.availabilityDayView.availabilityDays?.pageInfo?.endCursor}</Button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default AppPage;
