import { Checkbox, Input, Textarea, useId } from "@fluentui/react-components";
import { useAppointmentInputComponentStyle } from "./AppointmentInputComponentStyle";
import { useEffect, useState } from "react";
import { PersonRegular, MailRegular, PhoneRegular } from "@fluentui/react-icons";
import { GET_SETTINGS } from "../../graphql/query/settings";
import { useQuery } from "@apollo/client";
import JSEncrypt from "jsencrypt";

function AppointmentInputComponent(props: { disabled: boolean, onCompleted(data: any | null): void }) {
    const style = useAppointmentInputComponentStyle();
    const nameId = useId("nameId");
    const phoneId = useId("phoneId");
    const emailId = useId("emailId");
    const noteId = useId("noteId");

    const { disabled, onCompleted } = props;

    const { loading, /*error,*/ data } = useQuery(
        GET_SETTINGS
    );

    const [form, setForm] = useState<{
        name: string,
        phone: string,
        phoneReminder: boolean,
        email: string,
        emailReminder: boolean,
        notes: string
    }>({ name: '', phone: '', phoneReminder: false, email: '', emailReminder: false, notes: '' });

    useEffect(() => {
        if (!loading && data?.settings?.keys && form.email && form.name && form.notes && form.phone) {

            const isValid =
                validateEmail(form.email)
                && validateText(form.name)
                && validateText(form.notes)
                && validateText(form.phone)
                && (form.emailReminder || form.phoneReminder)
                ;

            if (!isValid) {
                onCompleted(null);
            }

            if (isValid) {

                // const model = {
                //     name: form.name,
                //     phone: form.phone,
                //     email: form.email,
                //     notes: form.notes,
                //     phoneReminder: form.phoneReminder,
                //     emailReminder: form.emailReminder
                // };

                const encrypt = new JSEncrypt();
                const publicKey = data.settings.keys.filter(item => item.name === 'public_key');
                if (publicKey[0]) {
                    encrypt.setPublicKey(publicKey[0].value);
                    const encrypted = {
                        name: encrypt.encrypt(form.name),
                        phone: encrypt.encrypt(form.phone),
                        email: encrypt.encrypt(form.email),
                        notes: encrypt.encrypt(form.notes),
                        phoneReminder: form.phoneReminder,
                        emailReminder: form.emailReminder
                    };

                    const encryptedModel = JSON.stringify(encrypted);
                    onCompleted(encryptedModel);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, loading, data?.settings.keys])


    useEffect(() => {

    }, [])

    return (
        <div className={style.container}>
            {/* <Label htmlFor={nameId}>Name</Label> */}
            <Input disabled={disabled} value={form.name} required={true} type="text" aria-label="Name" placeholder="Name" contentBefore={<PersonRegular />} id={nameId} onChange={(_, v) => {
                setForm({ ...form, name: v.value });
            }} />
            <div className={style.control}>
                <Input disabled={disabled} value={form.phone} required={true} type="tel" aria-label="Phone" placeholder="Phone" contentBefore={<PhoneRegular />} id={phoneId} onChange={(_, v) => {
                    setForm({ ...form, phone: v.value, phoneReminder: !!v.value && form.phoneReminder });
                }} />
                <Checkbox disabled={!form.phone || disabled} checked={form.phoneReminder} shape="circular" label="Send text reminder" onChange={(_, v) => {
                    setForm({ ...form, phoneReminder: v.checked === 'mixed' ? false : v.checked });
                }} />
            </div>

            <div className={style.control}>
                <Input disabled={disabled} value={form.email} required={true} type="email" aria-label="Email" placeholder="E-mail" contentBefore={<MailRegular />} id={emailId} onChange={(_, v) => {
                    setForm({ ...form, email: v.value, emailReminder: !!v.value && form.emailReminder });
                }} />
                <Checkbox disabled={!form.email || disabled} checked={form.emailReminder} className={style.item} shape="circular" label="Send E-mail reminder" onChange={(_, v) => {
                    setForm({ ...form, emailReminder: v.checked === 'mixed' ? false : v.checked });
                }} />
            </div>
            <Textarea disabled={disabled} value={form.notes} aria-label="Notes" resize="vertical" placeholder="Notes" id={noteId} onChange={(_, v) => {
                setForm({ ...form, notes: v.value });
            }} />
        </div>
    )
}

export default AppointmentInputComponent;

export type AppointmentData = {
    name: string,
    phone: number,
    email: string,
    notes: string,
    phoneReminder: boolean,
    emailReminder: boolean
}

function validateEmail(input: string): boolean {
    const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const regEx = new RegExp(reg);
    console.log(regEx.test(input));
    console.log(input);
    return input !== undefined && regEx.test(input);
}

function validateText(input: string): boolean {
    return input !== undefined && input.length > 2;
}