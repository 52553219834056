import { gql } from "../../__generated__/gql";

export const CREATE_RESERVATION = gql(/* GraphQL */ `
  mutation createReservation($start: String!) {
    createReservation(start: $start) {
      reservationCode,
      start,
      end,
      timeZone,
      reservationState,
      validTo,
    }
  }
`);

export const DELETE_RESERVATION = gql(/* GraphQL */ `
  mutation deleteReservation($reservationCode: String!) {
    deleteReservation(reservationCode: $reservationCode) {
      reservationCode,
      reservationState,
    }
  }
`);