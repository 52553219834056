import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
    /* https://react.fluentui.dev/?path=/docs/concepts-developer-positioning-components--default */
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    container: {
        display: "flex",
        flexDirection: 'column',
        ...shorthands.gap("20px"),
        ...shorthands.margin("10px", "10px"),
        ...shorthands.border('1px', 'none', 'black')
    },


    item: {
        justifyItems: "center",
        backgroundColor: 'transparent',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

});

