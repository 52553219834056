import { LabelProps, SkeletonItemProps, SpinnerProps, ToggleButtonProps, makeStyles, shorthands } from "@fluentui/react-components";

export const useSelectSupportOptionButtonStyle = makeStyles({
    container: {
        backgroundColor: 'transparent',
        display: "inline-flex",
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        ...shorthands.gap("6px"),
        ...shorthands.margin("10px", "0px"),
        '@media (max-width: 380px)': {
            ...shorthands.gap("2px"),
        }
    },
    item: {
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",

        // maxWidth: '100px',

    },
    skeletonItem: {
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
    },
    skeletonControl: {
        backgroundColor: 'transparent',
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        display: "inline-flex",
        flexDirection: 'row',
        alignContent: "center",
        ...shorthands.gap("5px"),
        ...shorthands.margin("0px", "0px", "0px", "0px"),
        '@media (max-width: 380px)': {
            ...shorthands.gap("2px"),
        }
    },
    control: {
        display: "flex",
        flexDirection: 'column',
        ...shorthands.gap("0px"),
    },
});

export const buttonStyle: ToggleButtonProps =
{
    shape: 'circular',
    size: 'large',
    style: {

        // maxWidth: '80px',
        borderRadius: '100px 100px 100px 100px'
    },
}

export const labelStyle: LabelProps =
{
    size: 'large',
}

export const leftNavigationButtonStyle: ToggleButtonProps =
{
    appearance: 'secondary',
    shape: 'circular',
    size: 'large',
    style: { borderRadius: '100px 10px 10px 100px' },
}

export const rightNavigationButtonStyle: ToggleButtonProps =
{
    appearance: 'secondary',
    shape: 'circular',
    size: 'large',
    style: { borderRadius: '10px 100px 100px 10px' },
}

export const spinnerStyle: SpinnerProps = {
    size: 'large',
    labelPosition: 'below'
}

export const leftNavigationButtonSkeletonStyle: SkeletonItemProps =
{
    animation: 'pulse',
    shape: 'rectangle',
    size: 40,
    style: { borderRadius: '100px 10px 10px 100px' },
}

export const rightNavigationButtonSkeletonStyle: SkeletonItemProps =
{
    animation: 'pulse',
    shape: 'rectangle',
    size: 40,
    style: { borderRadius: '10px 100px 100px 10px' },
}

export const centerNavigationButtonSkeletonStyle: SkeletonItemProps =
{
    animation: 'pulse',
    shape: 'rectangle',
    size: 40,
    style: {
        borderRadius: '100px 100px 100px 100px'
    },

}